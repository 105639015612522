import React, { useState } from "react";
import { useSelector } from "react-redux";
import { ReactComponent as NavIcon } from "../../assets/images/heebeeIcon.svg";
import Moment from "react-moment";
import { toUpper } from "lodash";

const AddMoneyInvoice = ({paymentMethod, customer_detail, amount, componentref, applyPromoCode }) => {
    const userDetailFromStore = useSelector((state) => state.userDetails);
    const state = useSelector(state => state);
    const { user: userData } = userDetailFromStore;
    const userDataList = userData || [];
    // console.log(userDetailFromStore?.user.branch);
    const [name, setName] = useState("");

    // console.log(applyPromoCode)

    return (
        <>
            <div ref={componentref} className="px-[10px]">
                <div className="flex flex-row  justify-between items-center my-4">
                    <NavIcon className="w-20 h-20 py-2 px-2 " />
                    <div>
                        <p className="text-sm">Heebee coffee private limited</p>
                        <p className="text-sm">{userDetailFromStore?.user?.branch}</p>
                        <Moment tz="Asia/Kolkata" format="MMM D, YYYY hh:mm a" className="text-sm">{new Date()}</Moment>
                    </div>
                </div>
                <div className="flex justify-end">
                    <h2 className="font-bold text-xs">Cashier: &nbsp; </h2>
                    <h2 className="capitalize text-xs">{userDataList?.full_name}</h2>
                </div>
                <div className="flex justify-end">
                    <h2 className="font-bold text-xs">Customer Name: &nbsp; </h2>
                    <h2 className="capitalize text-xs">{customer_detail.first_name} {customer_detail.last_name}</h2>
                </div>
                <div className="flex justify-end">
                    <h2 className="font-bold text-xs">Customer No.: &nbsp; </h2>
                    <h2 className="capitalize text-xs">{customer_detail.mobile_no}</h2>
                </div>

                <div className="flex flex-col border-y-2 border-dotted border-black py-2 my-2 ">
                    <div className="flex flex-row justify-between font-thin text-xs">
                        <div className="text-gray-900 font-bold">Previous Balance: &nbsp;</div>
                        <div className="font-bold">
                            ₹{customer_detail.wallet_balance}
                        </div>
                    </div>
                    <div className="flex flex-row justify-between font-thin text-xs">
                        <div className="text-gray-900  font-bold">Added Balance: &nbsp;</div>
                        <div className="font-bold">
                            ₹{amount}
                        </div>
                    </div>
                    {applyPromoCode?.applyPromoCode?.data?.length != 0 && <div className="flex flex-row justify-between font-thin text-xs">
                        <div className="text-gray-900  font-bold">Promocode : {applyPromoCode?.title} &nbsp;</div>
                        <div className="font-bold">
                            ₹{amount * (applyPromoCode?.extra_amount) / 100}
                        </div>
                    </div>}
                    <div className="flex flex-row justify-between font-thin text-xs">
                        <div className="text-gray-900 font-bold text-lg">Total Wallet Balance: &nbsp;</div>
                        <div className="font-bold text-lg">
                            ₹{applyPromoCode?.applyPromoCode?.data?.length != 0 ? parseFloat(customer_detail.wallet_balance) + parseFloat(amount) + parseFloat(amount * (applyPromoCode?.extra_amount)/100) : parseFloat(customer_detail.wallet_balance) + parseFloat(amount)}
                        </div>
                    </div>
                    {/* <div className="flex flex-row justify-between font-thin text-sm">
              <div className="text-gray-900 font-bold">TOTAL PAID</div>
              <div className="font-bold">
                ₹{Math.round((order.paid_price + order.change) * 100) / 100}
              </div>
            </div> */}
                    <div className="flex flex-row justify-between font-thin text-xs border-t-2 border-dotted border-black pt-2 mt-1">
                        <div className="text-gray-900 font-bold">PAYMENT METHOD</div>
                        <div className="font-bold capitalize">{paymentMethod} </div>
                    </div>

                </div>
                <div className="font-bold self-center my-2 grid place-content-center">
                    -------- ****** --------
                </div>
                <div className="text-xs self-center text-center">
                    <h6 className="font-bold mb-0">
                        Heebee coffee private limited
                    </h6>
                    <h6 className="font-bold mb-1">
                        <small>Subject to Ludhiana Jurisdiction</small>
                    </h6>
                    <h6>
                        <span className="font-bold">GSTIN </span>03AAFCH0428K1ZV
                    </h6>
                    <br />
                    <div className="text-xs m-2 self-center p-2 text-center font-extrabold">
                        <span>*</span>Thanks for choosing us! We appreciate the opportunity
                        to serve you.<span>*</span>
                    </div>
                </div>
            </div>
        </>
    );
};

export default AddMoneyInvoice;